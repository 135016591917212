.formio-component-file {
    .fileSelector {
        border: 1px solid var(--input-border-color);
        box-sizing: border-box;
        border-radius: $input-border-radius;
        height: 125px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--label-color);

        > a {
            margin-left: 5px;
        }
    }

    > .col-form-label {
        + div > div {
            > span {
                display: block;
                max-height: 250px;
                overflow: hidden;
                border-radius: $input-border-radius;
                position: relative;

                img {
                    width: 100% !important;
                }

                i {
                    position: absolute;
                    top: 5px;
                    right: 5px;
                    width: 21px;
                    height: 21px;
                    background-color: white;
                    border-radius: 21px;
                    color: var(--label-color);
                    cursor: pointer;

                    &:hover {
                        color: var(--form-text-color);
                    }

                    &::before {
                        margin-left: 5px;
                        margin-top: 2px;
                        display: inline-block;
                    }
                }
            }
        }
    }

    &.js-image-zoom img {
        cursor: pointer;
    }

    .files {
        margin-top: 0.5rem;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
    }

    .file {
        width: 221px;
        height: 140px;
        border: 1px solid #9ca7b5;
        border-radius: 8px;
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        background: transparent;
        transition: all 0.2s ease-in-out;
        color: var(--text-color);

        .row {
            .col-sm-12 {
                .alert-danger.alert-danger.bg-error {
                    display: block !important;
                }
            }
        }

        .download-file-link {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0.5rem;
            left: 0.5rem;
            padding: 0.5rem;
            background: $white;
            border-radius: 100%;
            width: 1.9rem;
            height: 1.9rem;
            &__image {
                width: 100%;
            }
        }


        .file-remove{
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 26px;
            height: 26px;
            position: relative;
            border-radius: 15px;
            background-color: white;
            cursor: pointer;

            .file {
                pointer-events: none;
            }

            &:hover{
                background-color: rgb(235, 235, 235);
            }

        }

        &:hover {

            .file__footer{
                background-color: rgb(218,220,224);
            }
        }

        .file__thumbnail{
            flex: 1;
            background-size: cover;
        }

        .file__footer{
            padding: 8px;
            display: flex;
            gap: 8px;

            .file-type{
                width: 20px;
                min-width: 20px;
                background: no-repeat center;
                background-size: contain;
                background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMDAwMDAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciICB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSI0OHB4IiBoZWlnaHQ9IjQ4cHgiPjxwYXRoIGQ9Ik0gNiAyIEMgNC45MDU3NDUzIDIgNCAyLjkwNTc0NTMgNCA0IEwgNCAyMCBDIDQgMjEuMDk0MjU1IDQuOTA1NzQ1MyAyMiA2IDIyIEwgMTggMjIgQyAxOS4wOTQyNTUgMjIgMjAgMjEuMDk0MjU1IDIwIDIwIEwgMjAgOCBMIDE0IDIgTCA2IDIgeiBNIDYgNCBMIDEzIDQgTCAxMyA5IEwgMTggOSBMIDE4IDIwIEwgNiAyMCBMIDYgNCB6Ii8+PC9zdmc+");
            
                &.file-type__application-pdf{
                    background-image: url("data:image/svg+xml;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAA6UlEQVRIid3VMUoDQRSH8Z8iWIh98AJCKmvLVDlIDpLSVsgJcoY0wcZGsDBnULKQKgiW2myKTCQJu+68TWzyh2GZ3fnme7wdGE4xfRQoG8awrWCesflBkg2cs6bEw38LwpI2gpCkraBSclYD133bX1OVHe7irzL3coUZpg2FNaauRbfp/UeEO8+14h2fWAaYUIt+MA4WFRLADe5wie8g+5u6f9BJm5Z4xgSDDK5RcI1HfGGEXpqXWBxD8JrmT3aP5z26xxC84M26/xHu8IU5XOjItUmVoEjP3Etnu/JCRvpit9pmzBN7YlkBKGx+jN1XwwsAAAAASUVORK5CYII=");
                }
            }


            .file-name{
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }


        // &[data-type="application/pdf"]{
        //     .file-type{
        //         background-image: url("data:image/svg+xml;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAA6UlEQVRIid3VMUoDQRSH8Z8iWIh98AJCKmvLVDlIDpLSVsgJcoY0wcZGsDBnULKQKgiW2myKTCQJu+68TWzyh2GZ3fnme7wdGE4xfRQoG8awrWCesflBkg2cs6bEw38LwpI2gpCkraBSclYD133bX1OVHe7irzL3coUZpg2FNaauRbfp/UeEO8+14h2fWAaYUIt+MA4WFRLADe5wie8g+5u6f9BJm5Z4xgSDDK5RcI1HfGGEXpqXWBxD8JrmT3aP5z26xxC84M26/xHu8IU5XOjItUmVoEjP3Etnu/JCRvpit9pmzBN7YlkBKGx+jN1XwwsAAAAASUVORK5CYII=");
        //     }
        // }
    }

    .file-wrapper {
        .file__footer {
            font-size: 0.8rem;
            color: var(--label-color);
            font-weight: 400;
            line-height: 120%;
            letter-spacing: -0.1px;
            margin-top: 0.6rem;
        }
    }


    .file__overlay{
        position: absolute;
        height: 100%;
        width: 100%;
        display: none;
        transition: all 0.2s ease-in-out;
        display: flex;
        flex-direction: column;

        .file__overlay-header{
            padding: 8px;
            align-items: right;
            position: relative;
            display: flex;
            justify-content: flex-end;
        }
        
        .file__overlay-body{
            flex: 1;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            padding-bottom: 60px;
        }

        .btn{
            .icon{
                transform: translateY(3px);
            }
        }
    }

    
}
