.formio-component-datagrid .datagrid-table,
.formio-component-datagrid .datagrid-table td,
.formio-component-datagrid .datagrid-table th {
    border: none !important;
}

.formio-component-datagrid {
    .datagrid-table {
        border: none;

        thead {
            tr {
                margin-bottom: 0.5rem;
                border-bottom: $table-border;

                th {
                    font-size: 0.9rem;
                    line-height: 120%;
                    padding-bottom: 1rem;
                    font-weight: 400;
                    color: #86868B;
                    vertical-align: bottom;
                    text-align: right;
                }
            }

            th:first-child {
                text-align: left;
            }
        }

        tbody {
            tr td:first-child {
                border-top-left-radius: 0.4rem;
                border-bottom-left-radius: 0.4rem;

                .choices__item {
                    justify-content: flex-start;
                }

                .formio-component-htmlelement {
                    text-align: left;
                }

                .input-as-status {
                    justify-content: flex-start;
                }

                .disabledValueContainer,
                .input-group {
                    justify-content: flex-start;
                    text-align: left;
                }
            }

            tr td:last-child {
                border-top-right-radius: 0.4rem;
                border-bottom-right-radius: 0.4rem;

                .card-body {
                    .formio-component-button {
                        display: flex;
                        justify-content: flex-end;
                        margin-top: 0.5rem;
                    }
                }
            }

            tr:hover {
                td {
                    background: #F5F5F7;
                }
            }

            .datagrid-table-empty-row {
                height: 6.25rem;

                .datagrid-table-empty-cell {
                    text-align: center;
                    margin: 2rem 0;
                }
            }

            .datagrid-table-empty-cell:hover {
                background: transparent;
            }

            .datagrid-table-empty-row:hover {
                background: transparent;
            }
        }

        th,
        td {
            border: none;
            font-size: $main-font-size;
            line-height: 125%;
            padding: 0.4rem 0.6rem;
            vertical-align: inherit;
            
            .choices__item {
                justify-content: flex-end;
                padding-right: 0;

                span {
                    text-align: right;
                }
            }

            .disabledValueContainer {
                text-align: right;
                justify-content: flex-end;
            }

            .input-group-disabled {
                margin-bottom: 0;
            }

            .input-group {
                justify-content: flex-end;
            }

            .formio-component-number,
            .formio-component-select,
            .formio-component-textfield {
                display: block;
            }

            .formio-component-datetime {
                grid-template-columns: 1fr;
            }

            .formio-component-htmlelement {
                text-align: right;
            }

            .input-as-status {
                justify-content: flex-end;

                .disabledValueContainer {
                    font-size: $main-font-size;
                }
            }

        }

        tr {
            &:has(td > .loan-status_INACTIVE) {

                a,
                .form-control {
                    color: #6E6E73 !important;
                }
            }
        }

        .form-control {
            font-size: 1.1rem;
            @include input-like-text-field;
            border-radius: 0;
            line-height: 1.6;
        }


        .formio-component-datetime {
            .form-control[type='hidden']+.form-control {
                border-radius: 0;
            }
        }

        a {
            padding: 0.18rem 0;
            display: inline-block;
        }

    }
}