main[pagename="task"] {
    .form-check-label:before {
        content: '' !important;
    }
    .formio-component-submit,
    .formio-component-submit2 {
        padding: 0 1.52rem;
    }
    .formio-component-collection {
        padding: 0 1.52rem;
    }
    .formio-component-collection+.formio-component-panel {
        padding: 0 1.52rem;
        margin-bottom: 1rem;
    }
    .formio-component-collection+.formio-component-panel+.formio-component-submit {
        padding: 0 1.52rem;
    }
    .formio-component-loanApplication+.formio-component-panel {
        padding: 0 1.52rem;
    }
    .formio-component-loanApplication+div+.formio-component-decision1 {
        padding: 0 1.52rem;
    }
    .formio-component-financialFigures {
        padding: 0 1.52rem;
    }
    .formio-component-decision {
        margin-bottom: 2.18rem;

        .col-form-label {
            margin-bottom: 0.8rem;
        }
    }
    .formio-component-decision {
        &+.formio-component-htmlelement {
            &+.formio-component-submit {
                padding: 0 1.52rem;
            }
        }
    }
    .formio-component-submit {
        padding: 0 1.52rem;
    }
    .card:has(.formio-component-collection) {
        div[ref="header"] {
            padding: 0.3rem 1.52rem;
        }
    }
    .formio-component-panel:has(.formio-component-reason) {
        padding: 0.3rem 1.52rem;
    }
    .formio-component-panel:has(.formio-component-decision) {
        padding: 0.3rem 1.52rem;
    }
    .formio-component-panel:has(.formio-component-callResult) {
        padding: 0.3rem 1.52rem;
    }
    .red-panel {
        .formio-component-htmlelement {
            p {
                font-size: 1.2rem;
            }
        }
        .formio-component-content {
            color: #FF7474;

            h4 {
                color: #FF0D0D;
                font-size: $main-font-size;
                font-weight: 400;
            }
        }
    }
    .formio-component-columns+.formio-component-decision {
        padding: 0.3rem 1.52rem;
    }

}
