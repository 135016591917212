.formio-scoring-component {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    max-width: 36rem;
    p {
        margin: 0 0 0 0.52rem;
    }
    img {
      height: 2rem;
    }
    p {
        margin: 0 0 0 0.5rem;
        font-size: 1.76rem;
        font-weight: 600;
        height: fit-content;
        line-height: 1;
        color: var(--text-color);
        opacity: 1;
        white-space: nowrap;
        margin-right: 1.18rem;
    }
    div {
        display: flex;
        align-items: center;
    }
}

div[ref="nested-loanApplicationScoring"] {
    .formio-component-htmlelement,
    .formio-component-textfield {
        margin: 0;
    }
}

.header-panel:has(> .card > div[ref="nested-loanApplicationScoring"]) {
    padding: 1rem 1.6rem;
}

.card:has(> div[ref="nested-loanApplicationScoring"]) {
    margin: 0 !important;
}

.tab-pane {
    .formio-component-htmlelement:has(div.formio-scoring-component) {
        .col-form-label {
            display: none;
        }
        .formio-scoring-component {
            display: flex;
            p {
                font-size: $main-font-size;
                font-weight: 400;
            }
        }
    }

    .formio-component-scoring {
        .input-group-disabled {
            display: flex;
        }
    }
}
