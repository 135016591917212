.file-preview {
    .next {
        margin-right: 20px;
    }
    .prev {
        margin-left: 20px;
    }
    .file-preview__header {
        div:nth-child(2) {
            margin-right: 20px;
        }
    }
}
