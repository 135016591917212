// Your variable overrides
$black: #1d1d1f;
$white: #ffffff;
$blue: #0a65ed;
$red: #f73743;
$tab-color: #000000;

//body
$body-color: #0d0f1c;

$h1-font-size: 2.8rem;

$border-radius: 0.35rem;

//forms

$form-label-color: #86868b;
$label-margin-bottom: 0.24rem;

$input-bg: white;
$input-border-width: 0.09rem;
$input-border-color: #dadee2;
$input-border-color-hover: #dadee2;
$input-font-size: 0.94rem;
$input-line-height: 1.65;
$input-group-addon-bg: $input-bg;
$input-color: $black;
$input-height: 2.6rem;

$input-check-label-color: #8b959e;

$form-check-input-gutter: 0.7rem;

$form-group-margin-bottom: 1.18rem;

$grid-gutter-width: 1.18rem;

$card-border-width: 0;

$nav-tabs-border-width: 0.12rem;
$nav-tabs-border-color: white;
$nav-tabs-border-radius: 0;
$nav-tabs-link-active-border-color: white white $blue;
$nav-tabs-link-active-color: $blue;

$card-spacer-x: 0;
$card-spacer-y: 0.6rem;
$card-border-width: 0;
$card-border-radius: 0;
$card-border-color: transparent;
$card-cap-bg: white;

$btn-padding-x: 1.5rem;

$table-border: 0.09rem solid #D2D2D7;

@import '~bootstrap/scss/bootstrap';
