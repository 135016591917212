@import '~bootstrap/scss/mixins/_breakpoints.scss';

.formio-component-tabs {
    > .card {
        > .card-header {
            padding: 0;
            background-color: unset;
            margin: 1.87rem 0 0.9rem;

            @media (max-width: 576px) {
                display: none;
            }
            
            .nav-tabs {
                margin: 0;
                padding: 0 1.4rem;

                .nav-link {
                    border-top: none;
                    border-left: none;
                    border-right: none;
                    color: var(--tab-color);
                    margin-bottom: -0.06rem;
                    border: none;
                    padding: 0;
                    font-size: $main-font-size;
                    letter-spacing: -0.3px;

                    &.active {
                        color: var(--tab-active-color);
                        background: var(--tab-active-bg);
                    }

                    &:not(.active):hover {
                        border-color: transparent;
                    }
                }

                .nav-item {
                    background: $white;
                    padding: 0.4rem 0.8rem;
                    border-radius: 0.4rem;
                    display: block;
                    margin-left: 0.3rem;
                }

                .nav-item:hover {
                    background: $header-panel-bg;
                }


                .nav-item.active {
                    background: $black;
                }
            }
        }

        > .card-body {
            padding: 0.6rem 0 1.18rem;
            .formio-component-operations {
                td:nth-child(1) {
                    min-width: 5.4rem;
                }
                td:nth-child(2) {
                    min-width: 7rem;
                }
            }
        }

        .tab-pane{
            padding: 0.3rem 1.52rem;
            @media (max-width: 576px) {
                display: block !important;
            }
        }
    }
}
