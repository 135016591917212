.ant-pagination{
    text-align: left;
    padding-left: 1.2rem;
    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-item-link,
    li{
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: #F6F7F8;
        border-radius: 0.4rem;
        min-height: 2.4rem;
        width: 2rem;

        font-weight: 600;
        font-size: 0.9rem;
        line-height: 150%;

        text-align: center;

        color: #86868B;
        border: none;
    }

    .ant-pagination-item-active{
        background: transparent;
        color: var(--form-text-color);
    }
}