.formio-container .formio-modal{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    background: rgba(0,0,0,0.3);
}

.formio-container .formio-modal.formio-hidden{
    display: none;
}

.formio-container .formio-modal.open{
    display: block;
}

.formio-container  .formio-modal.fade .card{
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out,-webkit-transform .3s ease-out;
    -webkit-transform: translate(0,-50px);
    transform: translate(0,-50px);
}

.formio-container  .formio-modal.show .card {
    -webkit-transform: none;
    transform: none;
}


.formio-container  .formio-modal-open .formio-modal{
    overflow-x: hidden;
    overflow-y: auto;
}

.formio-container .formio-modal .card{
    max-width: 500px;
    margin: 1.75rem auto;
}