.alert-panel{
    position: relative;
    border-radius: 0.5rem;
    padding: 0.94rem 1.5rem 0.3rem;

    --alert-panel__background: #f5f6f7;
    --alert-panel__value-error: #000000;
    --alert-panel__label-error: #828b94;


    &.alert-panel_error{
        --alert-panel__background: #FFE4E4;
        --alert-panel__value-error: #000000;
        --alert-panel__label-error: #5e656b;
    }

    background-color: var(--alert-panel__background);

    label{
        color:  var(--alert-panel__label-error);
    }

    input.form-control{
        color: var(--alert-panel__value-error);
        border-radius: 0;
    }
}