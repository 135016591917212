.ant-input:hover {
    border-color: var(--input-border-color-focus) !important;
    border-width: 0.15rem;
}

.ant-input:focus,
.ant-input-focused {
    border-color: var(--input-border-color-focus) !important;
    border-width: 0.15rem;
}

.ant-input:focus {
    box-shadow: none;
}

.ant-input {
    background: var(--input-bg);
}
