.helper_well-content-horizontal,
.well_content-horizontal {
    > .card {
        > div {
            display: flex;
            align-items: center;
            > * + * {
                margin-left: 0.3rem;
            }

            > * + .input-as-status{
                margin-left: 0.6rem;
                margin-bottom: 0;
            }

            .input-as-status {
                font-size: 1.5rem;;
            }
        }
    }

    &.well_justify-content-end {
        > .card {
            > div {
                justify-content: flex-end;
            }
        }
    }

    &.well_justify-content-center {
        > .card {
            > div {
                justify-content: center;
            }
        }
    }

    .input-as-status{
        transform: translateY(0.18rem);
    }
}

.well_content-horizontal-with-space{
    > .card {
        > div {
            display: flex;
            > * + * {
                margin-left: 0.3rem;
            }
        }
    }
}

.header-panel {
    padding: 1.6rem;
    margin-top: 1rem;
    border-radius: 0.47rem;
    background-color: var(--header-panel-bg);
    border-color: var(--header-panel-bg);

    .formio-component-htmlelement {
        margin-bottom: 0.9rem;
        color: var(--form-text-color);
        font-size: 1.1rem;
        line-height: 125%;
        letter-spacing: -0.3px;
    }

    .formio-componen {
        margin-bottom: 0.3rem;
    }

    .col-form-label {
        margin-bottom: 0 !important;
    }
    
    .input-suffix,
    .disabledValueContainer {
        font-size: 1.5rem !important;
        color: var(--form-text-color);
        font-weight: 600;
    }
    .col-form-label {
        font-size: 1.5rem;
        line-height: 120%;
        font-weight: 400;
        color: var(--label-color) !important;
    }
    .form-group:has(> div > input:disabled) {
        margin-bottom: auto;
        max-height: fit-content;
    }
    .formio-component-textfield {
        .form-control {
            text-align: right;
        }
    }
}

.helper_no-labels {
    label {
        display: none;
    }
}

.helper_inputs-like-text {

    .form-group{
        .col-form-label {
            font-weight: 400;
            line-height: 125%;
            margin-bottom: 0.35rem;
        }
    }

    .form-control {
        @include input-like-text-field;
    }

    .input-group{
        .input-group-prepend{
            .input-suffix{
                background: transparent;
                border: none;
                padding-left: 0;
                padding-top: 0;
                padding-bottom: 0;
                color: #000000;
            }
        }
        
    }

    .formio-component-datetime{
      .input-group-append{
        display: none;
      }
    }
}

.helper_input-like-text {
    .input-group {
        width: auto;
        .input-group-append {
            display: none;
        }

        .input-group-prepend{
            .input-suffix{
                background: transparent;
                border: none;
                padding-left: 0;
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }
    .form-control {
        @include input-like-text-field;
        flex: initial;
        width: auto;
    }
}

.helper_input-font-size-large {
    .input-suffix,
    .form-control {
        font-size: 1.5rem;
        font-weight: 600;
        height: fit-content;
        line-height: 1;
        color: var(--form-text-color);
        opacity: 1;
    }
}

.helper_input-group-no-prefix {
    .input-group {
        .input-group-prepend {
            display: none;
        }
    }
}

.helper_input-group-no-suffix {
    .input-group {
        .input-group-append {
            display: none;
        }
    }
}

.formio-dropdown-trigger {
    .formio-dropdown-trigger {
        background: $black !important;
        &:focus {
            box-shadow: none !important;
        }
    }
}

.formio-dropdown {
    text-align: end;

    
    .formio-dropdown-menu {
        display: none;
        border: 0.09rem solid #e9e9e9;
        background: white;
        width: fit-content;
        box-shadow: 0 0.6rem 1.76rem rgba(27, 49, 82, 0.21);
        padding: 0.3rem 0;
        border-radius: 0.5rem;

        &[data-popper-reference-hidden] {
            visibility: hidden;
        }

        &.open {
            display: block;
            z-index: 1;
        }

        .btn {
            display: block;
            width: 100%;
            background-color: white;
            color: #0d0f1c;
            border: none;
            border-radius: 0;

            &:hover {
                background: #e9e9e9 !important;
            }
        }

        &:last-child {
            .card {
                margin-bottom: 0 !important;
            }
        }
    }
}


.dropdownContainer {
    min-width: 8.82rem;
    border: 0.09rem solid #e9e9e9;
    background: white;
    width: fit-content;
    box-shadow: 0 0.6rem 1.76rem rgba(27, 49, 82, 0.21);
    padding: 0.3rem 0;
    border-radius: 0.5rem;

    .btn {
        display: block;
        width: 100%;
        background-color: white;
        color: #0d0f1c;
        border: none;
        border-radius: 0;

        &:hover {
            background: #e9e9e9 !important;
        }
    }
}

.task-reccomendedLoanProduct-container {
    .formio-component-recommendedLoanProductInfo {
        margin-bottom: 1.4rem;
        .form-control {
            &[disabled],
            &:disabled {
                padding: 0.375rem 0;
            }
        }
    }
    &:nth-child(2) {
        align-items: center;
    }
}

.card {
    .card {
        padding: 0;
    }
}

.card:has(> div > div.header-panel) {
    padding: 0;
}

.ant-list-split {
    .ant-list-item {
        border: none;
    }
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: $white;
}

.ant-form-item-control-input {
    min-height: auto;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
    background-color: var(--btn-bg);
    border-color: var(--btn-bg);
}

.formio-component-select.formio-component-reason {
    margin-bottom: 0.6rem!important;
}

.formio-component-htmlelement {
    a {
        width: fit-content;
    }
}
