@mixin input-like-text-field {
  padding: 0;
  height: auto;
  border: none;
  background-color: transparent;
  border-radius: 0;
  line-height: 1;

  &:active, &:focus, &:focus-visible {
    box-shadow: none;
    outline: none;
    border: none;
  }
}

@mixin status-style ($padding, $min-width, $border-radius){
  width: fit-content;
  text-align: left;
  font-size: 1.7em;
}

@mixin status-color($color) {
   color: $color !important;
}

@mixin status-circle($color) {
   &:before {
       content: '';
       display: inline-block;
       width: 0.45em;
       height: 0.45em;
       border-radius: 100%;
       margin-right: 0.3rem;
       background: $color;
   }
}
