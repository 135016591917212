@font-face {
    font-family: 'SF Pro Text';
    src: url('../assets/fonts/SFProText-Bold.woff2') format('woff2'),
        url('../assets/fonts/SFProText-Bold.woff') format('woff'),
        url('../assets/fonts/SFProText-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Text';
    src: url('../assets/fonts/SFProText-Semibold.woff2') format('woff2'),
        url('../assets/fonts/SFProText-Semibold.woff') format('woff'),
        url('../assets/fonts/SFProText-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Text';
    src: url('../assets/fonts/SFProText-Regular.woff2') format('woff2'),
        url('../assets/fonts/SFProText-Regular.woff') format('woff'),
        url('../assets/fonts/SFProText-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Text';
    src: url('../assets/fonts/SFProText-Medium.woff2') format('woff2'),
        url('../assets/fonts/SFProText-Medium.woff') format('woff'),
        url('../assets/fonts/SFProText-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Text';
    src: url('../assets/fonts/SFProText-Heavy.woff2') format('woff2'),
        url('../assets/fonts/SFProText-Heavy.woff') format('woff'),
        url('../assets/fonts/SFProText-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

// 1rem = 16px

*, body{
    font-family: 'SF Pro Text';
    font-size: 16px;
  
  /* For viewports between 1600px and 1919px */
  @media (min-width: 1600px) and (max-width: 1919px) {
    font-size: 13.76px;
  }
  
  /* For viewports between 1440px and 1599px */
  @media (min-width: 1440px) and (max-width: 1599px) {
    font-size: 12.8px;
  }
  
  /* For viewports between 1366px and 1439px */
  @media (min-width: 1366px) and (max-width: 1439px) {
    font-size: 12.16px;
  }
  
  /* For viewports between 1280px and 1365px */
  @media (min-width: 1280px) and (max-width: 1365px) {
    font-size: 10.24px;
  }
  
  /* For viewports between 1024px and 1279px */
  @media (min-width: 1024px) and (max-width: 1279px) {
    font-size: 9.22px;
  }
  
  /* For viewports between 768px and 1023px */
  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 8.19px;
  }
  
  /* For viewports between 576px and 767px */
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 7.17px;
  }
  
  /* For viewports below 576px */
  @media (max-width: 575px) {
    font-size: 6.15px;
  }
  
  
} 
