.builderComponent {
    padding-left: 0.5rem;
    .formbuilder {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        gap: 2em;
        margin-top: 0.9rem;
    }
    .formcomponents {
        padding: 0;
    }
    .builder-sidebar_search {
        display: none;
    }
    .form-builder-panel {
        .form-builder-group-header {
            display: none;
        }
    }
    .formarea {
        border: 1px solid #A9AFB7;
        max-width: 80%;
        border-radius: 5px;
        padding: 1rem;
        
        .formio-component-email,
        .formio-component-datetime,
        .formio-component-number,
        .formio-component-select,
        .formio-component-textfield {
            display: block;
            margin-bottom: 0.7rem;
            .col-form-label {
                margin-bottom: 0.5rem;
            }
        }
    }
    #group-container-customBasic {
        padding: 0 !important;
        .btn-primary {
            margin: 0;
            margin-bottom: 9px;
            background: var(--btn-bg);
            border-color: var(--btn-bg);
            white-space: nowrap;
            font-size: 11px;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 33px;
            padding: 5px 10px;
            border-radius: 8px;
            i {
                display: none;
            }
        }
        .btn:last-child {
            margin-bottom: 0;
        }
    }
    
        .row:nth-child(2) {
            display: flex;
            flex-direction: column;
        }
}

.formio-dialog-content {
    background:#ffffff !important;
    .lead {
        color: var(--text-color);
        font-weight: 600;
        font-size: 1.7rem;
        margin-left: 3.6rem;
    }
    a:has(> i.fa-window-restore) {
        display: none;
    }
    .formio-dialog-close {
        right: 1.7rem !important;
        top: 0.6rem !important;
    }

    .formio-component-tabs  {
        .card {
            .card-header-tabs {
                margin-left: -0.5rem;
                padding: 1rem 0;
            }
            .nav-item:first-child {
                margin-left: 0 !important;
            }
            .tab-pane {
                padding: 0.8rem 2.2rem !important;
            }
        }
    }

    .formio-component-email,
    .formio-component-datetime,
    .formio-component-number,
    .formio-component-select,
    .formio-component-textfield {
        display: block;
        margin-bottom: 0.7rem;
        .col-form-label {
            margin-bottom: 0.5rem;
        }
    }

    .form-check-label {
        display: flex;
        margin: 1.5rem 0 1.5rem 0;
        .form-check-input {
            + span {
                &::after {
                    top: 2%;
                    left: 0;
                }
            }

            &:checked {
                + span {
                    &::after {
                        left: 1.4rem !important;
                    }
                }
            }
        }
        .fa-question-circle {
            margin-top: 0.35rem;
            margin-left: 0.2rem;
        }
    }
}

.component-edit-container {
    .card-header {
        padding: 0  26px !important;
    }
    .row:nth-child(2) {
        flex-direction: column;
        .col {
            max-width: 100%;
        }
        .col:nth-child(2) {
            padding: 31px 36px;
            .card-header {
                padding: 0 !important;
                padding-bottom: 15px !important;
            }
            .btn-success {
                margin-left: 25px;
            }
            .preview-panel {
                margin-bottom: 45px;
            }
        }
        div:has(> button.btn-success) {
            margin-top: 10px;
            display: flex;
            justify-content: flex-end;

            .btn-danger {
                background-color: #000000;
                border-color: #000000;
            }
            .btn-success {
                background-color: #0a65ed;
                border-color: #0a65ed;
            }
        }
    }
}
