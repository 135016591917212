@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

body{
  margin: 0;
  padding: 0;
  /* font-family: 'Montserrat', sans-serif; */
}

.clickable{
  cursor: pointer;
}
