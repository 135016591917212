.ant-select-multiple .ant-select-selection-search-input, .ant-select-multiple .ant-select-selection-search-mirror {
    height: 1.9rem;
}

.ant-select-multiple .ant-select-selector::after {
    line-height: 0;
}

.ant-select-item {
    min-height: auto;
    font-size: 0.9rem;
    padding: 0.3rem 0.8rem;
    line-height: 1.4rem;
}

.ant-select-selection-item {
    height: 1.5rem !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding: 0.5 !important;
    line-height: 1.4rem !important;
    border-radius: 0.12rem !important;
}

.ant-select-selection-item-remove {
    display: flex !important;
    align-items: center !important;

    svg {
        width: 0.6rem;
        height: 0.6rem;
        margin: 0.6rem;
        margin-left: 0.3rem;
    }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #F5F5F7;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    color: var(--form-text-color);
}

.ant-select-selection-placeholder {
    line-height: 1.8rem;
}

.ant-select-arrow {
    width: 0.75rem;
    height: 0.75rem;
    margin-top: -0.5rem;
}

.ant-select-selector {
    background: var(--input-bg) !important;
}

.rc-virtual-list-holder-inner {
    background: var(--input-bg);
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background: var(--selected-select-item);
}
