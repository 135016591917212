main[pagename="borrowers"] {
    .formio-component-container.formio-component-address {
        .formio-component-columns.row {
            display: block;
            .col-md-10,
            .col-md-2 {
                max-width: 100%;
            }
        }
    }
}
