main[pagename="productsGroup"] {
    input[type="checkbox"]:disabled {
        background: red;
      }
    .table-bordered {
        .form-check-label {
        padding-left: 0.9rem;
            
        .form-check-input {
            position: relative;
            width: 0.9rem;
            height: 0.9rem;
            opacity: 1;
    
            + span {
                font-size: 0.94rem;
                line-height: 1.5;
                color: red;
    
                &::before {
                    content: '';
                    display: inline-block;
                    width: 1.5rem;
                    height: 1.5rem;
                    position: relative;
                    background-color: $blue;
                    border-radius: $border-radius;
                    border: $input-border-width solid var(--input-border-color);
                    left: 0;
                    top: 0;
                }
            }
    
            &:checked {
                + span {
                    color: red;
                    &::before {
                        background-color: $primary;
                        border-color: $primary;
                    }
    
                    &::after {
                        position: absolute;
                        content: '';
                        display: inline-block;
                        left: 0.23rem;
                        top: 0.2rem;
                        width: 1.18rem;
                        height: 1.18rem;
                        background-repeat: no-repeat;
                        background-position: center;
                        border-color: transparent;
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='13' viewBox='0 0 15 13' fill='none'%3E%3Cpath d='M1 7.36842L4.85185 12L14 1' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
                    }
                }
            }
        }
    
        &:hover {
            .form-check-input:not(:disabled) {
                + span {
                    &::before {
                        border: $input-border-width solid var(--input-border-color-hover);
                    }
                }
            }
        }
    }

    }
}