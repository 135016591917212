.formio-component-paginationComponent {
    .pagination-container {
        display: flex;
        gap: 0.5rem;
        align-items: center;

        .pagination-btn {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background: #F6F7F8;
            border-radius: 0.4rem;
            min-height: 2.4rem;
            width: 2rem;
            font-weight: 600;
            font-size: 0.9rem;
            line-height: 150%;
            text-align: center;
            color: #86868B;
            border: none;
            .pagination-btn-arrow {
                width: 10px;
            }
        }

        .active {
            background: transparent;
            color: var(--form-text-color);
        }
    }

    /* CSS styles for the custom dropdown */
.page-size-dropdown {
    display: inline-block;
    position: relative;
  }
  
  .dropdown-button {
    background-color: #FFFFFF;
    border: 1px solid #EDEDED;
    border-radius: 0.4rem;
    padding: 0.5rem 1rem;
    cursor: pointer;
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 150%;
    color: #86868B;
    user-select: none;
  }
  
  .dropdown-button::after {
    content: '\25BE'; /* Unicode for down arrow */
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
  }
  
  /* Hide the checkbox input */
  #dropdown-toggle {
    display: none;
  }
  
  /* Style the dropdown content */
  .dropdown-content {
    display: none;
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border: 1px solid #EDEDED;
    border-radius: 0 0 0.4rem 0.4rem;
    min-width: 6rem;
    z-index: 2;
  }
  
  /* Show the dropdown when the checkbox is checked */
  #dropdown-toggle:checked + .dropdown-button + .dropdown-content {
    display: block;
  }
  
  .dropdown-option {
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .dropdown-option:hover {
    background: #F6F7F8;
  }
  
}
