.red-panel {
    padding: 1rem 1.6rem;
    background: #FFE4E4;
    border-radius: 0.5rem;
    margin-bottom: 0.6rem;
    .form-group {
        margin-bottom: 0 !important;
    }
    .formio-component-declineReason {
        margin-bottom: 0.01px;
        align-items: center;
        label {
            margin-right: 0.3rem;
        }
        input,
        .col-form-label {
            font-weight: 400;
            line-height: 125%;
            padding: 0;
            white-space: nowrap;
        }
        .field-wrapper {
            display: block;
            margin-left: 1.6rem;
        }
    }
    .formio-component-closedAt {
        margin-bottom: 0;
    }
    .col-md-6:nth-child(2) {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        input {
            font-weight: 400;
            font-size: 0.8rem;
            line-height: 150%;
        }
    }
    .card-body {
        margin-bottom: 0 !important;
    }
    .formio-component-columns {
        div {
            .col-form-label {
                color: #FF7474;
            }
            .choices__item--selectable,
            .disabledValueContainer {
                color: #FF0D0D;
            }
        }
    }
    .choices__list--multiple {
        .choices__item {
            margin: 0;
            color: #FF0D0D !important;
            font-weight: 400;
            font-size: 1.2rem !important;
            line-height: 150%;
        }
    }
    .formio-component:not(.formio-component-columns) {
        display: block;
    }
}
