.formio-component-panel{
    >.card{
        border: none !important;
        .card-header{
            cursor: auto;
            margin: 1.6rem 0;
            padding: 0;
        }
        .card {
            padding: 0;
        }
    }

    .card-title{
        font-weight: 600;
        font-size: 1.75rem;
        letter-spacing: -0.3px;
        line-height: 120%;
    }
}