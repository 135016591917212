.ant-btn {
    height: 2.6rem;
    padding: 0.6rem 0.7rem;
    border-radius: 0.5rem;
}
.ant-btn-primary {
    min-width: 8.7rem;
    background-color: $blue;

    &:focus,
    &:hover {
        background-color: darken($blue, 10%);
    }
}
