.formio-pdf-viewer {
    .react-pdf__Page {
        display: flex;
        justify-content: center;
    }
    &_toolbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &_page-container {
        display: flex;
        justify-content: space-between;
    }
    &_page-numbers {
        display: flex;
        align-items: center;
        padding: 6px 6px 0;
    }
    &_btn-group {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &_zoom-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &_download-container {
        display: flex;
        align-items: center;
    }
    &_download-btn {
        display: flex;
        align-items: flex-end;
    }
}
