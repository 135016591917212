
.formio-component-datetime {
    .form-control[type='hidden'] + .form-control {
        border-radius: $border-radius 0 0 $border-radius;
    }

    .input-group{
        .input-group-append{
            display: none;
        }
    }

    .form-control:disabled {
        text-align: right;
    }

    .form-control {
        &:active,
        &:focus:not(.is-invalid) {
            border-right: 0.2rem solid var(--input-border-color-focus) !important;
        }
    }

    .input-group:has(> .input-group-append) {
        .form-control {
            &.is-invalid {
                border-right: 0.09rem solid $red !important;
        
                &+.input-group-append {
                    border-color: $red;
                    border-left: none;
                }
        
                &:focus {
                    &+.input-group-append {
                        box-shadow: none;
                    }
                    box-shadow: none;
                }
            }
        }
    }
}