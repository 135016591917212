.formio-component-form {
    h1{
        margin-bottom: 2.35rem;
    }
    h2 {
        font-weight: 600;
        font-size: 2.9rem;
        line-height: 105%;
        letter-spacing: -0.03em;
        margin: 0.6rem 0 0.2rem;
        padding: 0 0.94rem 0 1.6rem;
        color: var(--text-color);
        word-break: break-word;

        a {
            font-size: 1em;
            color: var(--form-text-color);
        }
    }

    iframe{
        border: none;
    }

    div[ref="nested-loanTitle"] {
        .input-as-status {
            .form-control {
                font-size: 1.5rem;
            }
        }
    }

    .card:has(h2) {
        .formio-component-htmlelement:has(a) {
            a {
                color: var(--form-text-color);
                padding: 0 0.94rem 0 1.6rem;
                display: flex;
                font-size: $main-font-size;
                align-items: center;
                text-decoration: none;
            }

            a:hover {
                color: var(--text-color);
            }

            a:before {
                content: '<';
                display: flex;
                font-size: 0.8rem;
                justify-content: center;
                align-items: center;
                margin-right: 0.5rem;
                text-decoration: none;
                width: $main-font-size;
                height: $main-font-size;
                border: 0.1rem solid $black;
                border-radius: 100%;
                padding-bottom: 0.1rem;
                padding-right: 0.1rem;
            }
        }
    }
}

